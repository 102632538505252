import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import { PATH_HOME } from '../common/constants/paths';

export default defineNuxtRouteMiddleware(() => {
  const { data } = useAuth();
  const $auth = data.value ?? null;

  if (!$auth) {
    return;
  }

  if ($auth.requirements.length === 0) {
    return navigateTo({ path: PATH_HOME });
  }
});
